import React, {
  createContext,
  ReactElement,
  useContext,
  useState,
} from 'react';
import { ExcalidrawImperativeAPI } from '../../types';

type EditorType = {
  excalidrawAPI: ExcalidrawImperativeAPI | null;
  setExcalidrawAPI: React.Dispatch<React.SetStateAction<ExcalidrawImperativeAPI | null>>
};

const Excalidraw = createContext({} as EditorType);

export const ExcalidrawProvider = ({ children } : {
  children: ReactElement
}) => {
  const [excalidrawAPI, setExcalidrawAPI] =
  useState<ExcalidrawImperativeAPI | null>(null);
  return (

    <Excalidraw.Provider
      value={{
        excalidrawAPI,
        setExcalidrawAPI
      }}>
      {children}
    </Excalidraw.Provider>
  );
};

export const useExcalidraw = () => useContext(Excalidraw);
