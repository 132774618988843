import React from "react";
import { AppState, BinaryFiles } from "../types";
import { capitalizeString, muteFSAbortError } from "../utils";
import "./LibraryButton.scss";
import { save } from "./icons";
import { ExportType } from "../scene/types";
import { ExportCB } from "./ImageExportDialog";
import { useExcalidraw } from "./context/ExcalidrawContext";
import { exportCanvas } from "../data";

export const EstudaSaveButton: React.FC<{
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
  files: BinaryFiles;
  isMobile?: boolean;
}> = (props) => {
  const { appState, isMobile, files } = props

  const { excalidrawAPI } = useExcalidraw();

  const showLabel = !isMobile;

  const createExporter =
  (type: ExportType): ExportCB =>
  async (exportedElements) => {
    appState.exportEmbedScene = true;

    const fileHandle = await exportCanvas(
      type,
      exportedElements,
      appState,
      files,
      {
        exportBackground: appState.exportBackground,
        name: appState.name,
        viewBackgroundColor: appState.viewBackgroundColor,
      },
      false
    )
      .catch(muteFSAbortError)
      .catch((error) => {
        console.error(error);
      });

      parent.postMessage({
        TYPE: "EXCALIDRAW_EXPORT_BLOB",
        source: fileHandle
      }, "*");
  };

  const pngHandler = createExporter('svg')
  // TODO barnabasmolnar/redesign
  // not great, toolbar jumps in a jarring manner
  if (appState.isSidebarDocked && appState.openSidebar === "library") {
    return null;
  }

  return (
    <label title={`${capitalizeString("Salvar")}`}>
      <input
        className="ToolIcon_type_checkbox"
        type="button"
        name="editor-library"
        onClick={async () => {
          const elements = excalidrawAPI?.getSceneElements()
          if (!elements) {
            return
          }

          pngHandler(elements);
        }}
        aria-label={capitalizeString("Salvar")}
        aria-keyshortcuts="0"
      />
      <div className="library-button">
        <div>{save}</div>
        {showLabel && (
          <div className="library-button__label">Salvar</div>
        )}
      </div>
    </label>
  );
};
