import { register } from "../actions/register";
import { loadFromBlob, normalizeFile } from "../data/blob";
import { FileId } from "../element/types";
import { KEYS } from "../keys";
import { randomId } from "../random";
import { BinaryFileData, ExcalidrawImperativeAPI } from "../types";

interface EstudaImagesData {
  id: FileId,
  blob: Blob,
}

async function fetchEstudaImages(images: Array<any>) {
  let imagesData: EstudaImagesData[] = [];

  await Promise.all(
    images.map(async (image) => {
      try {
        const blob = await fetchBlob(image.url);

        imagesData.push({
          "id": image["id"],
          "blob": blob
        });
      } catch (error) {}
    })
  );

  return imagesData;
}

async function fetchBlob(url: string) {
  return fetch(url).then(async (response) => {
    return await response.blob();
  });
}

export const LoadEstudaLibaryFiles = async (excalidrawAPI: ExcalidrawImperativeAPI | null, images: Array<any>) => {
  if (!excalidrawAPI) {
    return
  }

  await fetchEstudaImages(images).then(estudaImagesData => {
    estudaImagesData.map(estudaImageData => {
      const reader = new FileReader();

      reader.readAsDataURL(estudaImageData.blob);

      reader.onload = function () {
        const file: BinaryFileData[] = [
          {
            id: estudaImageData.id,
            dataURL: reader.result as BinaryFileData["dataURL"],
            mimeType: estudaImageData.blob.type as BinaryFileData["mimeType"],
            created: Date.now(),
            lastRetrieved: Date.now(),
          },
        ];

        excalidrawAPI.addFiles(file);
      }
    });
  });
}

export const getFileFromLink = async (url: string) => {
  // PROXY BY-PASS CROSS
  url = `https://img.estuda.com/img2/${url}`;

  const fileRes = await fetch(url);

  const blob = await fileRes.blob();
  let fileName = "excalidraw"

  const file = new File([blob], fileName, {
    type: blob.type,
  });

  return file;
}

export const loadSceneByUrl = register({
  name: "loadSceneByUrl",
  trackEvent: { category: "export" },
  perform: async (elements, appState) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const imageUrl = urlParams.get("image")

    try {
      let imageFile: File | null = null

      imageFile = await getFileFromLink(imageUrl || "")

      const {
        elements: loadedElements,
        appState: loadedAppState,
        files,
      } = await loadFromBlob(
        await normalizeFile(imageFile),
        appState,
        null,
        imageFile.handle,
      );

      loadedAppState.isLoading = false;

      return {
        elements: loadedElements,
        appState: loadedAppState,
        files,
        commitToHistory: true,
      };
    } catch (error: any) {}

    return false;
  },
  keyTest: (event) => event[KEYS.CTRL_OR_CMD] && event.key === KEYS.O,
});

export const convertToLibraryItems = (items: Array<any>) => {
  const libraryItems: any = [];

  items.map(item => {
    libraryItems.push({
      "status": "estuda",
      "elements": [
        {
          "type": "image",
          "version": 475,
          "versionNonce": 369220967,
          "isDeleted": false,
          "id": randomId(),
          "fillStyle": "solid",
          "strokeWidth": 1,
          "strokeStyle": "solid",
          "roughness": 4,
          "opacity": 100,
          "angle": 0,
          "x": 0,
          "y": 0,
          "strokeColor": "transparent",
          "backgroundColor": "#000000",
          "width": item.width,
          "height": item.height,
          "seed": 1233457726,
          "groupIds": [],
          "roundness": null,
          "boundElements": [],
          "updated": 1678218034178,
          "link": null,
          "locked": false,
          "status": "saved",
          "fileId": item.id as FileId,
          "scale": [
            1,
            1
          ]
        }
      ],
      "id": randomId(),
      "created": 1678195025566
    });
  });

  return libraryItems;
};
